<template>
    <section :class="{'pinned-content-hero': true, 'multiple-content': slice.variation === 'multipleContent'}">
        <div v-if="isFilledContentRelationship(slice.primary.mainContent) && !slice.primary.mainContent.isBroken" class="content">
            <div :class="{'main-article': true, collection: slice.primary.mainContent.type === 'collection'}">
                <PrismicImage
                    v-if="
                        hasProperty(slice.primary.mainContent.data, 'releaseDate') &&
                        slice.primary.mainContent.type !== 'collection' &&
                        slice.primary.mainContent.data?.thumbnail
                    "
                    :class="{thumbnail: true, cropped: slice.primary.mainContent.type === 'article'}"
                    :field="slice.primary.mainContent.data?.thumbnail"
                />
                <CollectionThumbnail
                    v-if="slice.primary.mainContent.type === 'collection'"
                    :collection="(slice.primary.mainContent as FilledContentRelationshipField<'collection', string, CollectionDocumentData> | CollectionDocument)"
                />
                <div class="content-info">
                    <CategoryPill class="category-pill" :category="slice.primary.mainContent.data?.category ?? null" />

                    <h1 class="title">{{ slice.primary.mainContent.data?.title }}</h1>
                    <div
                        v-if="
                            slice.primary.mainContent.type !== 'guide' &&
                            slice.primary.mainContent.type !== 'collection' &&
                            slice.variation === 'default'
                        "
                        class="time-info"
                    >
                        <template v-if="hasProperty(slice.primary.mainContent.data, 'releaseDate') && slice.primary.mainContent.data?.releaseDate">
                            <Calendar class="icon" />
                            <span>
                                {{
                                    new Date(slice.primary.mainContent.data?.releaseDate).toLocaleDateString(locale, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })
                                }}
                            </span>
                        </template>
                        <template v-if="hasProperty(slice.primary.mainContent.data, 'duration') && slice.primary.mainContent.data.duration !== ''">
                            <div class="dot" />
                            <Clock class="icon" />
                            <span>{{ slice.primary.mainContent.data?.duration }}</span>
                        </template>
                    </div>
                    <PrismicRichText class="excerpt" :field="slice.primary.mainContent.data?.excerpt" />
                    <div class="tags">
                        <TagPill v-for="tag in slice.primary.mainContent.tags" :key="tag" :tag="tag" />
                    </div>

                    <NuxtLink class="link" :to="linkResolver(slice.primary.mainContent)">
                        {{ t(getCtaTranslationKey(slice.primary.mainContent.type)) }}
                    </NuxtLink>
                </div>
            </div>
            <template v-if="slice.variation === 'multipleContent'">
                <div v-if="slice.variation === 'multipleContent'" class="side-column">
                    <PrismicRichText :field="slice.primary.sideContentTitle" class="side-column-title" />
                    <NuxtLink v-for="item in sideContents" :key="item.id" :to="linkResolver(item)" class="side-content">
                        <div>
                            <h3 class="side-title">{{ item.data?.title }}</h3>
                            <div v-if="item.type !== 'guide' && item.type !== 'collection' && item.data?.releaseDate" class="time-info">
                                <Calendar class="icon" />
                                <span>
                                    {{
                                        new Date(item.data?.releaseDate).toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric'})
                                    }}
                                </span>
                            </div>
                        </div>
                        <VJoyButton variant="secondary" icon="arrow-right" class="side-button" />
                    </NuxtLink>
                </div>
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {inject} from 'vue';
    import {getSliceComponentProps, PrismicImage, PrismicRichText} from '@prismicio/vue';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {CollectionDocumentData, CollectionDocument, PinnedContentHeroSlice} from '@prismicio-types';
    import CategoryPill from '~/components/CategoryPill.vue';
    import Calendar from '~/components/icons/Calendar.vue';
    import Clock from '~/components/icons/Clock.vue';
    import CollectionThumbnail from '~/components/CollectionThumbnail.vue';
    import linkResolver from '~/app/prismic/link-resolver';
    import {useTranslation, usePrismicTypeGuards, useContentHelpers} from '#imports';
    import {USER_LOCALE} from '~/app/keys';
    import type {FilledContent} from '~~/src/composables/useContentHelpers';
    import TagPill from '~/components/TagPill.vue';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import {VJoyButton} from '@maltjoy/core-vue';

    function getCtaTranslationKey(type: 'article' | 'guide' | 'successStory' | 'collection'): string {
        if (type === 'successStory') {
            return `resources.successStories.readLink`;
        }
        return `resources.${type}s.readLink`;
    }

    const {isFilledContentRelationship} = useContentHelpers();
    const {hasProperty} = usePrismicTypeGuards();
    const {t} = useTranslation();
    const locale = inject(USER_LOCALE);
    const props = defineProps({...getSliceComponentProps<PinnedContentHeroSlice>()});
    const sideContents = props.slice.items.map((item) => item.sideContent).filter((item) => isFilledContentRelationship(item)) as FilledContent[];
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;

    .pinned-content-hero {
        background-color: var(--joy-color-quaternary-10);
        background-image: url('~/assets/svg/heroPills.svg');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: auto 100%;
        padding: 110px 0 140px;
        @media (max-width: $mobile-breakpoint) {
            background-size: 100% auto;
            padding: 60px 0 90px;
        }
        .content {
            @include content-width;
            .main-article {
                display: grid;
                grid-template-columns: 1.2fr 1fr;
                column-gap: 150px;
                &.collection {
                    grid-template-columns: 0.8fr 1.2fr;
                }
                @media (max-width: $mobile-breakpoint) {
                    display: flex;
                    flex-direction: column;
                }
            }
            .category-pill a:not(:hover)::after {
                background-color: var(--joy-color-quaternary-10);
            }
            .thumbnail {
                width: 100%;
                height: 500px;
                object-fit: contain;
                object-position: center;
                &.cropped {
                    object-fit: cover;
                }
                @media (max-width: $mobile-breakpoint) {
                    height: auto;
                    width: 100%;
                    max-height: 350px;
                    margin-bottom: var(--joy-core-spacing-4);
                }
            }
            .title {
                margin-top: var(--joy-core-spacing-4);
                margin-bottom: var(--joy-core-spacing-8);
            }
            .time-info {
                display: flex;
                align-items: center;
                color: var(--joy-color-neutral-40);
                .dot {
                    height: 4px;
                    width: 4px;
                    background-color: var(--joy-color-neutral-60);
                    border-radius: 50%;
                    margin: 0px var(--joy-core-spacing-4);
                }
                .icon {
                    margin-right: var(--joy-core-spacing-2);
                }
            }
            .excerpt {
                margin-top: var(--joy-core-spacing-5);
                p {
                    color: var(--joy-color-neutral-40);
                }
            }
            .tags {
                margin-top: var(--joy-core-spacing-5);
                display: flex;
                gap: var(--joy-core-spacing-1);
                flex-wrap: wrap;
            }
            .link {
                @include link;
                display: inline-block;
                margin-top: var(--joy-core-spacing-5);
            }
        }
        &.multiple-content {
            padding: var(--joy-core-spacing-9) 0 80px;
            @media (max-width: $mobile-breakpoint) {
                padding: var(--joy-core-spacing-5) 0 var(--joy-core-spacing-9);
            }
            .content {
                display: grid;
                grid-template-columns: 1.2fr 1fr;
                column-gap: 150px;

                @media (max-width: $mobile-breakpoint) {
                    display: flex;
                    flex-direction: column;
                    gap: var(--joy-core-spacing-11);
                }
                .thumbnail {
                    height: 300px;
                    @media (max-width: $mobile-breakpoint) {
                        height: auto;
                        max-height: 330px;
                    }
                }
                .main-article {
                    display: flex;
                    flex-direction: column;
                    &.collection {
                        display: grid;
                        grid-template-columns: 290px auto;
                        gap: var(--joy-core-spacing-9);
                        @media (max-width: $mobile-breakpoint) {
                            display: flex;
                            gap: 0px;
                        }
                    }
                    h1 {
                        font-size: 40px;
                        @media (max-width: $mobile-breakpoint) {
                            font-size: var(--joy-font-size-secondary-200);
                            margin-bottom: var(--joy-core-spacing-5);
                        }
                    }
                    .content-info {
                        margin-top: var(--joy-core-spacing-5);
                    }
                    .link {
                        @media (max-width: $mobile-breakpoint) {
                            display: inline-block;
                        }
                    }
                }
                .side-column {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: stretch;
                    .side-column-title h2 {
                        color: var(--joy-color-primary-50);
                        font-family: var(--joy-font-family-title);
                        font-size: var(--joy-font-size-secondary-200);
                        font-weight: var(--joy-font-weight-normal);
                    }
                }
                .side-content {
                    display: flex;
                    align-items: center;
                    gap: var(--joy-core-spacing-12);
                    padding: var(--joy-core-spacing-7) 0;
                    border-bottom: solid 1px var(--joy-color-neutral-30);
                    width: 100%;
                    justify-content: space-between;
                    .time-info {
                        margin-top: var(--joy-core-spacing-3);
                    }
                    .side-button {
                        --button-color-secondary: var(--joy-color-primary-50);
                        --button-bg-color-secondary-hover: var(--joy-color-primary-10);
                        padding: 0 10px;
                        .joy-icon {
                            margin-right: 0px;
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .collection-thumbnail {
            max-width: 290px;
        }
    }
</style>
